export const FirebaseConfig = {
	"projectId": "taxiszolnok-f5f48",
	"appId": "1:96741638382:web:3601eebecef372c34d73ff",
	"databaseURL": "https://taxiszolnok-f5f48-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "taxiszolnok-f5f48.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyADeiy7zjjzcjEksNGVSr9E_ReY6iQImm0",
	"authDomain": "taxiszolnok-f5f48.firebaseapp.com",
	"messagingSenderId": "96741638382",
	"measurementId": "G-YRWRCST47M"
};